import { apiUrl } from '../environment/env';

// Function to subscribe email
export const subscribeEmail = async (sEmail) => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/subuscribe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sEmail })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error subscribing email:", error);
    }
};

// Function to get all subscribers
export const getSubscribers = async () => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/pan-global/get-subuscribers`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching subscribers:", error);
    }
};
// Function to add contact us message
export const addContactUs = async (sName, sEmail, sDescription) => {
    try {
        const response = await fetch(`https://origin-staging.mi-recall.com/organiser-api/api/v1/add-contact-us`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sName, sEmail, sDescription })
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error adding contact us message:", error);
    }
};

// Function to get all contact us messages
export const getContactUsMessages = async () => {
    try {
        const response = await fetch(`${apiUrl}/get-contact-us`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching contact us messages:", error);
    }
};
